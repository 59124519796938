//import '../components/LoadScript';

import Landing from './components/Landing';
// import HackathonLanding from './components/hackathon/HackathonLanding';

const LandingPage = () => {
    return <Landing />; // 추후 랜딩 페이지에 관련해서 해커톤 기간이 끝나면 다시 되돌릴 계획입니다.
    // return <HackathonLanding />; // 해커톤 기간에 다시 이 주석을 해제해주세요.
};

export default LandingPage;
